/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading, Text, Link } from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';
import ProductReviews from '../../plugins/storefront/src/templates/product/ProductReviews';

const ReviewsPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { locale } = props.pageContext;

  const {
    reviews: { nodes: reviewsData },
  } = props.data;

  const reviews = reviewsData.map((r) => {
    r.title = r.translation[locale].title;
    r.content = r.translation[locale].content;
    r.name = r.translation[locale].name;
    return r;
  });

  return (
    <>
      <Helmet
        title={t({
          id: `Reviews.Title`,
          message: `Reviews`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Reviews.MetaDescription`,
            message: `Hiar extensions reviews`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Reviews.BreadcrumbsTitle`,
              message: `Reviews`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        {reviews && reviews.length > 0 ? (
          <ProductReviews
            reviews={reviews}
            reviewsNumber={reviews.length}
            paginationNum={10}
            headingAs="h1"
          />
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export const ReviewsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
    reviews: allReviewsJson {
      nodes {
        id
        translation {
          ru {
            title
            name
            content
          }
          en {
            title
            name
            content
          }
        }
        score
        productHandle
        createdAt
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ReviewsPage;
